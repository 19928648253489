import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { actionAddEvent, actionAddEventToGroup, actionAddStudentToKurs, actionAddTutorToKurs, actionAllKurses, actionAllTutors, actionAllUsers, actionDeleteEvent, actionRegisterGroup, actionRemoveStudentFromKurs , actionGroupInfo, actionDeleteGroup, actionChangeEvent } from '../actions/actions'
import Header from '../components/Header'
import "../css/kursy.css"
import "../css/modalwindow.css"
import { store } from '../store/store'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { actionAddGroupInfo, actionAddUserInfo, actionDeleteUserInfo  } from '../store/actionsReducer'
import BackspaceIcon from '@mui/icons-material/Backspace';

import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import { Link, useLocation } from 'react-router-dom'

import HowToRegIcon from '@mui/icons-material/HowToReg';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setHours, setMinutes , format } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import plcHolder from "../images/undraw_teacher_re_sico 1.png"

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RemoveIcon from '@mui/icons-material/Remove';

import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import "../css/placeholder.css"

const Placeholder = ({setAdding}) => {
  return (
    <div className="placehold">
        <img src={plcHolder} alt="placeholder" />
        <div>
                    <span>Wybierz kurs albo</span><br/>
                    <strong onClick={() => setAdding(true)}>Dodaj nowy</strong>
        </div>
    </div>
  );
};

// onClick={() => handleUserInfo(item.userId , item.role, item.first_name, item.last_name, item.email, item.date_of_birth, item.nationality, item.id_validity_date, item.passport_number, item.field_of_study, item.study_mode, item.group, item.zoomLink)}
const Modal = ({ isModalOpen, setModalOpen, eventAdd , setEventAdd , students , group_id  }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStudents, setSelectedStudents] = useState([]);

  const closeModal = () => {
    setModalOpen(false);
    setEventAdd(false)
  };

  const [title , setTitle] = useState('')
  const [info , setInfo] = useState('')
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStudentsPick = (studentId) => {
    // Toggle the selection state for the clicked student
    setSelectedStudents((prevSelected) => {
      if (prevSelected.includes(studentId)) {
        // Remove the student if already selected
        return prevSelected.filter((id) => id !== studentId);
      } else {
        // Add the student if not selected
        return [...prevSelected, studentId];
      }
    });
  };

  const filteredStudents = Array.isArray(students)
    ? students.filter(
        (item) =>
          item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.last_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

    const handleSendAllSelectedStudents = () => {
        
        for (const item of selectedStudents || []) {
            store.dispatch(actionAddStudentToKurs({
                user_id: item,
                group_id: group_id,
            }));
        }
        setModalOpen(false);
        // setStudentAdded(true)
      };
      

    const handleSendAllSelectedData = () => {
        store.dispatch(actionAddEvent({event_name: title , start_time:format(startTime,"HH:mm") , end_time: format(endTime,"HH:mm") , date: format(startDate,'yyyy/MM/dd') , event_description: info}))
        setModalOpen(false)
        // console.log({event_name: title , start_time:format(startTime,"HH:mm") , end_time: format(endTime,"HH:mm") , date: format(startDate,'yyyy/MM/dd') , event_description: info})
        //!  отсюда мы записываем в базу данных и выводим всем членам которые привязаны к группе
    }

  return (
    <div>
      {isModalOpen  && !eventAdd ? (
        <div className='modal-overlay'>
          <div className='modal_window'>
            <div className='modal_header'>
              <div className='search_modal'>
                <input
                  name='search'
                  placeholder='Szukaj'
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <button className='close_button' onClick={closeModal}>
                X
              </button>
            </div>
            <div className='modal_body'>
              {filteredStudents?.length > 0 ? (
                filteredStudents?.map((item) => (
                  <div
                    key={item.userId}
                    className='row_students'
                    onClick={() => handleStudentsPick(item.userId)}
                  >
                    <h4>
                      {item?.first_name} {item?.last_name}
                    </h4>
                    {selectedStudents.includes(item.userId) ? (
                      <HowToRegIcon style={{color: "green"}}/>
                    ) : (
                      <span>+</span>
                    )}
                  </div>
                ))
              ) : (
                <p>No matching students found</p>
              )}
              <button onClick={() => handleSendAllSelectedStudents()}>dodaj wybranych uczniów</button>
            </div>
          </div>
        </div>
      ) : (
        <div className='modal-overlay'>
            <div className='modal_window'>
                <div className='modal_header'>
                    <button className='close_button' onClick={closeModal}>X</button>
                </div>
                <div className='modal_body'>
                    <div className='modal_body_picker'>
                        <span>Tytuł</span>
                        <input placeholder='' onChange={(e) => setTitle(e.target.value)} value={title}/>
                    </div>
                    <div className='modal_body_picker'>
                        <span>od</span>
                        <DatePicker
                            selected={startTime}
                            onChange={(date) => setStartTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm a"
                        />
                    </div>
                    <div className='modal_body_picker'>
                        <span>do</span>
                        <DatePicker
                            selected={endTime}
                            onChange={(date) => setEndTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm a"
                        />
                    </div>
                    <div className='modal_body_picker'>
                        <span>Datę</span>
                        <DatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="yyyy/MM/dd"
                        icon={
                          <CalendarMonthIcon/>
                        }
                        />
                    </div>
                    <div className='modal_body_picker'>
                        <span>Dodatkowe informacje</span>
                        <input placeholder='' onChange={(e) => setInfo(e.target.value)} value={info}/>
                    </div>
                    <button onClick={() => handleSendAllSelectedData()}>dodaj event</button>
                </div>
            </div>
      </div>
      )
    }
    </div>
  );
};

export default Modal;


export const ModalConnect = connect((state) => ({
    students: state.promise.users?.payload,
  }))(Modal);


const ModalChangeEvent = ({openEditingModal , setOpenEditingModal , eventInfo}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    text: '',
  });

  const [idEvent , setIdEvent] = useState('')
  const [title, setTitle] = useState('');
  const [info, setInfo] = useState('');
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Zablokuj przewijanie strony, gdy modal jest widoczny
    return () => {
      document.body.style.overflow = ''; // Odblokuj przewijanie strony po zamknięciu modala
    };
  }, []);

  function convertTimeToDateString(inputTime) {
    // Assuming inputTime is in the format HH:mm
    const [hours, minutes] = inputTime.split(':');
  
    // Create a Date object and set hours and minutes
    const dateObj = new Date();
    dateObj.setHours(parseInt(hours, 10));
    dateObj.setMinutes(parseInt(minutes, 10));
  
    // Convert the date to the desired string format
    const dateString = dateObj.toString();
  
    return dateString;
  }

  function convertDateStringToCustomFormat(inputDate) {
    // Assuming inputDate is in the format "yyyy/MM/dd"
    const [year, month, day] = inputDate.split('/');
  
    // Create a Date object
    const dateObj = new Date(year, month - 1, day); // Month is zero-based
  
    // Convert the date to the desired string format
    const dateString = dateObj.toString();
  
    return dateString;
  }

  useEffect(() => {
    if (eventInfo && eventInfo.length > 0) {
      console.log(eventInfo)
      setIdEvent(eventInfo[0]?.event_id)
      setTitle(eventInfo[0]?.event_name);
      setInfo(eventInfo[0]?.description);
      setStartTime(new Date(convertTimeToDateString(eventInfo[0].start_time)))
      setEndTime(new Date(convertTimeToDateString(eventInfo[0].end_time)))
      setStartDate(new Date(convertDateStringToCustomFormat(eventInfo[0].date)))
    }
  }, [eventInfo]);


  const handleSendAllSelectedData = () => {
    store.dispatch(actionChangeEvent({eventId: idEvent , event_name: title , start_time:format(startTime,"HH:mm") , end_time: format(endTime,"HH:mm") , date: format(startDate,'yyyy/MM/dd') , event_description: info}))
    // store.dispatch(actionAddEvent({event_name: title , start_time:format(startTime,"HH:mm") , end_time: format(endTime,"HH:mm") , date: format(startDate,'yyyy/MM/dd') , event_description: info}))
    setOpenEditingModal(false)
  }

  return(
    <div className={`contact-modal-overlay show`}>
    <div className='contact_modal'>
      <div className='modal_content'>
        <CloseIcon onClick={() => setOpenEditingModal(false)} className='close_button' />
                    <div className='modal_body_picker'>
                        <span>Tytuł</span>
                        <input placeholder='' onChange={(e) => setTitle(e.target.value)} value={title}/>
                    </div>
              <form>
                    <div className='modal_body_picker'>
                        <span>od</span>
                        <DatePicker
                            selected={startTime}
                            onChange={(date) => setStartTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm a"
                        />
                    </div>
                    <div className='modal_body_picker'>
                        <span>do</span>
                        <DatePicker
                            selected={endTime}
                            onChange={(date) => setEndTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm a"
                        />
                    </div>
                    <div className='modal_body_picker'>
                        <span>Datę</span>
                        <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="yyyy/MM/dd"
                            icon={
                              <CalendarMonthIcon/>
                            }
                        />
                    </div>
                    <div className='modal_body_picker'>
                        <span>Dodatkowe informacje</span>
                        <input placeholder='' onChange={(e) => setInfo(e.target.value)} value={info}/>
                    </div>
                    <button style={{marginTop: 20}} type='submit' onClick={() => handleSendAllSelectedData()}>Zmień event</button>
              </form>
      </div>
    </div>
  </div>
  )
}


const KursesDashboard = ({data , tutors , events , setCreateNewKurs}) => {
    const date = [
        {
            title: "English lesson",
            startDate: "13/12/2023",
            endDate: "13/12/2023",
            startTime: "08:00:00",
            endTime: "09:00:00",
            id: 133,
            bg_color: "#FFAB91",
            element: 
            <Link className='link' to="zoommtg://zoom.us/join?confno=8529015944&pwd=&uname=Nobody%20-%2051800000000" 
            onClick={(e) => {
              e.preventDefault();
              window.open("zoommtg://zoom.us/join?confno=8529015944&pwd=&uname=Nobody%20-%2051800000000", "_blank");
            }}
            >zoom.us</Link>,
        }
    ]


  const [formData, setFormData] = useState([]);
  const [addStudentModal , setAddStudentModal] = useState(false)
  const [eventAdd, setEventAdd] = useState(false)
  const [tutorsOfCourse , setTutorsOfCourse] = useState('')

  const [hoveredStudent, setHoveredStudent] = useState(null);

  const [openEditingModal , setOpenEditingModal] = useState(false)

  const handleMouseEnter = (user_id) => {
    setHoveredStudent(user_id);
  };

  const handleMouseLeave = () => {
    setHoveredStudent(null);
  };

  const reduxState = useSelector((state) => state.actions);
  const [localState, setLocalState] = useState([]);

  useEffect(() => {
      if(reduxState.group_info){
        setLocalState(reduxState || []);
        console.log(reduxState)
      }
  }, [reduxState]);


    useEffect(() => {
        if(data){
            setFormData({
                ...formData,
                group_id: data.group_id,
                group_name: data.group_name,
                group_description: data.group_description,
                tutor: data.tutors,
                students: data.students,
                events: data.events
            })
            setTutorsOfCourse(data.tutors)
        }
    }, [data ,openEditingModal ])

    useEffect(() => {
        if(events){
            store.dispatch(actionAddEventToGroup({event_id: events.event_id , group_id: formData?.group_id}))
        }
    }, [events])
    

    const handleChangeTutor = (event) => {
      if (formData.group_id) {
        store.dispatch(actionAddTutorToKurs({ user_id: event, group_id: formData.group_id }));
        setTutorsOfCourse(event);
      }
    };
    
    // если препод уже есть то тогда выводим его, если препода нету то тогда селект если по селекту выбрали то диспатчем 

    const handleChangeModalContent = () => {
        setEventAdd(true)
        setAddStudentModal(true)
    }

    const handleChangeModalContent1 = () => {
        setAddStudentModal(true)
        setEventAdd(false)
    }

    const getDayFromDate = (dateString) => {
      const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      
      // Create a Date object from the dateString
      const dateObject = new Date(dateString);
    
      // Get the day of the week index (0-6)
      const dayIndex = dateObject.getDay();
    
      // Get day and month with leading zeros
      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      
      // Return the formatted date string
      return `${daysOfWeek[dayIndex]}`;
    };


    const getDayFromDate1 = (dateString) => {
      // Create a Date object from the dateString
      const dateObject = new Date(dateString);
    
      // Get day and month with leading zeros
      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      
      // Return the formatted date string
      return `${day}.${month}`;
    }

    // ${day}.${month}

   const handleDeleteEvent = (event_id) => {
     store.dispatch(actionDeleteEvent({event_id: event_id}))
   }

   const handleDeleteStudentFromKurs = (user_id ,group_id ) => {
     store.dispatch(actionRemoveStudentFromKurs({user_id: user_id , group_id: group_id}))
   }

   
    return (
        <>
          {localState.group_info  ? (
            <div className='kurses_dashboard'>
              <DataSaverOnIcon className='schdedul' sx={{ fontSize: "50px" }} onClick={() => handleChangeModalContent()} />
              <div className='kurses_dashboard_info'>
                <h1>{formData?.group_name}</h1>
                <p>{formData?.group_description}</p>
              </div>
              <div className='kurses_tutor'>
                <span>lektor</span>
                <strong><ArrowDropDownIcon /></strong>
                <select
                  id="tutorSelect"
                  value={tutorsOfCourse}
                  onChange={(e) => handleChangeTutor(e.target.value)}
                >
                  {formData?.tutor?.length === 1 ? (
                    <>
                      {formData?.tutor?.map((item) => (
                        <option key={item.user_id} value={item.user_id}>
                          {item.first_name} {item.last_name}
                        </option>
                      ))}
                      {tutors?.map((tutor) => (
                        <option key={tutor.userId} value={tutor.userId}>
                          {tutor.first_name} {tutor.last_name}
                        </option>
                      ))}
                    </>
                  ) : (
                    <>
                      <option value="">Select a tutor...</option>
                    {tutors && Array.isArray(tutors) && tutors.map((tutor) => (
                      <option key={tutor.userId} value={tutor.userId}>
                        {tutor.first_name} {tutor.last_name}
                      </option>
                    ))}
                    </>
                  )}
                </select>
              </div>
              <div className='kurses_students'>
                <span>studenci</span>
                <button onClick={() => handleChangeModalContent1()}>Dodaj studenta do kursy</button>
                {formData.students?.map((item) => (
                  <div
                    key={item.user_id}
                    className='row_students_kurses'
                    onMouseEnter={() => handleMouseEnter(item.user_id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className='row_students_kurses1'>
                      <span>{item?.first_name}</span>
                      <span>{item?.last_name}</span>
                    </div>
                    {hoveredStudent === item.user_id && (
                      <GroupRemoveIcon
                        style={{ color: 'red' }}
                        onClick={() => handleDeleteStudentFromKurs(item.user_id, formData?.group_id)}
                      />
                    )}
                  </div>
                ))}
              </div>
              <div className='kurses_events'>
                <span>zajęcia</span>
                <div className='box_date_wrapper'>
                  {formData?.events?.sort((a, b) => new Date(a?.date) - new Date(b?.date)).map((item) => (
                    <div className='box_date' key={item?.event_id}>
                      <div className='event_box'>
                        <span className='data'>{getDayFromDate(item?.date)}</span>
                        <div className='box_time'>
                            <span className='data1'>{getDayFromDate1(item?.date)}</span>
                            <span className='time'>{item?.start_time} - {item?.end_time}</span>
                        </div>
                        <div className='box_event_content'>
                          <span className='title'>{item.event_name}</span>
                          <span className='desc'>{item.description}</span>
                        </div>
                      </div>
                      <div className='icons' key={item?.event_id}>
                        <CloseIcon className='close_icon' style={{ color: 'red' }} onClick={() => handleDeleteEvent(item?.event_id)} />
                        <EditIcon className='pencil' onClick={() => setOpenEditingModal(true)}/>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <Placeholder setAdding={setCreateNewKurs}/>
          )}
          {addStudentModal && <ModalConnect isModalOpen={addStudentModal} setModalOpen={setAddStudentModal} eventAdd={eventAdd} setEventAdd={setEventAdd} group_id={formData?.group_id} />}
          {openEditingModal && <ModalChangeEvent setOpenEditingModal={setOpenEditingModal} eventInfo={formData?.events} openEditingModal={openEditingModal}/>}
        </>
      );
}


export const KursesDashboardConnect = connect((state) => ({
    data: state.actions.group_info,
    tutors: state.promise.tutors?.payload,
    events: state.promise.addEvent?.payload,
  }))(KursesDashboard);


const Menu = ({kursy , createNewKurs ,setCreateNewKurs , addKursAction , updatedInfo , addStudAction , deleteStudentAction , eventAddedAction , tutorAction , deleteEventAction , changeEvent}) => {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const [group_id , setGroup_id] = useState([])

  const [hoveredStudent, setHoveredStudent] = useState(null);

  const handleMouseEnter = (user_id) => {
    setHoveredStudent(user_id);
  };

  const handleMouseLeave = () => {
    setHoveredStudent(null);
  };

  const handleGroupInfo = (group_id , group_name , group_description , tutors, students ,events) => {
    store.dispatch(actionAddGroupInfo(group_id , group_name , group_description, tutors , students , events))
    setGroup_id(group_id)
  }

  const handleCreateNewKurs = () => {
      setCreateNewKurs(true)
  }

  useEffect(() => {
    if(addStudAction || tutorAction || deleteEventAction){
      store.dispatch(actionGroupInfo(group_id))
    }
    if(deleteStudentAction || eventAddedAction || changeEvent){
      store.dispatch(actionGroupInfo(group_id))
    }
  }, [group_id ,addStudAction , deleteStudentAction , eventAddedAction , tutorAction , deleteEventAction , changeEvent])
  
  
  useEffect(() => {
      if(updatedInfo){
        store.dispatch(actionAddGroupInfo(updatedInfo?.group_id , updatedInfo?.group_name , updatedInfo?.group_description, updatedInfo?.tutors , updatedInfo?.students , updatedInfo?.events))
      }
  }, [updatedInfo , changeEvent])
    
    // ! после того как произошло добавление студентов в группу нам надо 1/ делать запрос еще раз и диспатчить ту же самую групуу

    // ! обновление после того как добавил студента 

    const filteredStudents = Array.isArray(kursy)
  ? kursy.filter((item) =>
      item.group_name.toLowerCase() !== 'admingroup'
    )
  : [];

    const handleDeleteKurs = () => {
      store.dispatch(actionDeleteGroup({group_id: group_id}))
  }
    
    return (
        <div className='search_students_menu'>
            <div className='row_menu_wrapper'>
                <div className='column_students_wrapper'>
                      {
                        splitLocation[1] === "kursy" ? (
                          <>
                            <div className='search'>
                                <button onClick={handleCreateNewKurs}>Dodaj kurs</button>
                            </div>
                            <div className='top_search'>
                                <span>Kursy</span>
                            </div>
                          </>
                        ) : (
                          <>
                          </>
                        )
                      }
                        <div className='column_students'>
                          {kursy?.length > 0 ? (
                            filteredStudents?.map((item) => (
                              <div className='row_students' key={item?.group_id} onClick={() => handleGroupInfo(item?.group_id, item?.group_name, item?.group_description, item?.tutors, item?.students, item?.events)}  onMouseEnter={() => handleMouseEnter(item.group_id)}
                              onMouseLeave={handleMouseLeave}>
                                <h4>{item?.group_name}</h4>
                                <span>{item?.group_description}</span>
                                {hoveredStudent === item.group_id && (
                                  <DeleteSweepIcon
                                    sx={{ color: 'red' }}
                                    onClick={handleDeleteKurs}
                                  />
                                )}
                              </div>
                            ))
                          ) : (
                            <p>No matching groups found</p>
                          )}
                        </div>
                      {/* <div className='search'>
                          <button onClick={handleCreateNewKurs}>Dodaj kurs</button>
                      </div> */}
                </div>
      </div>
    </div>
    )
}

const CreateKursDashboard = ({ lektors , setCreateNewKurs }) => {
  
  const [pickedLector, setPickedLector] = useState('')
  const [nameOf , setNameOf] = useState('')
  const [desc , setDesc] = useState('')

  const handleLektorPicked = (value) => {
      setPickedLector(value)
  }

  const handleCreateGroup = () => {
    // console.log({group_name: nameOf, group_description: desc, user_id: pickedLector})
    store.dispatch(actionRegisterGroup({group_name: nameOf, group_description: desc, user_id: pickedLector}))
    setCreateNewKurs(false)
  }

  return (
    <div className='create_kurs'>
      <div className='create_kurs_wrapper'>
        <div className='box_kurs'>
          <span>Nazwa</span>
          <input name="nazwa" type="text" value={nameOf} onChange={(e) => setNameOf(e.target.value)}/>
        </div>
        <div className='box_kurs'>
          <span>Wykladowca</span>
          <select name="lektor" onChange={(e) => handleLektorPicked(e.target.value)} value={pickedLector}>
            <option value="">Select a tutor...</option>
            {lektors && Array.isArray(lektors) && lektors.map((lektor) => (
              <option key={lektor.userId} value={lektor.userId}>
                {lektor.first_name} {lektor.last_name}
              </option>
            ))}
          </select>
        </div>
        <div className='box_kurs'>
          <span>Opis</span>
          <textarea value={desc} onChange={(e) => setDesc(e.target.value)}></textarea>
        </div>
        <div className='box_kurs'>
          <button onClick={handleCreateGroup}>Utwórz kurs</button>
        </div>
      </div>
    </div>
  );
};

const Kursy = ({courses, tutors , addKursAction, addStudAction ,updatedInfo , deleteStudentAction  , eventAddedAction , tutorAction , deleteEventAction , deleteKurs , changeEvent}) => {
    const [kursy , setKurses] = useState({})

    const [checkNewStudent , setCheckNewStud] = useState(false)

    const [createNewKurs , setCreateNewKurs] = useState(false)

    const [lektors , setLektors] = useState([])


    // ! kursy data
    useEffect(() => {
      const fetchData = async () => {
        try {
          await store.dispatch(actionAllKurses());
          await store.dispatch(actionAllUsers());
          await store.dispatch(actionAllTutors());
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }, [checkNewStudent, addKursAction, addStudAction , deleteStudentAction , eventAddedAction , tutorAction , deleteEventAction , deleteKurs ,changeEvent]); 


    useEffect(() => {
      if(deleteKurs){
        store.dispatch(actionAddGroupInfo(kursy[0]?.group_id , kursy[0]?.group_name , kursy[0]?.group_description, kursy[0]?.tutors , kursy[0]?.students , kursy[0]?.events))
      }
    }, [deleteKurs])
    

    useEffect(() => {
        if(courses){
            setKurses(courses)
        }
        if(tutors){
            setLektors(tutors)
        }
  }, [courses, tutors, addStudAction ,changeEvent ])


  return (
    <div className='kursy_wrapper'>
        <Header/>
        <Menu kursy={kursy} createNewKurs={createNewKurs} setCreateNewKurs={setCreateNewKurs} addKursAction={addKursAction}  updatedInfo={updatedInfo} addStudAction={addStudAction} deleteStudentAction={deleteStudentAction} eventAddedAction={eventAddedAction} tutorAction={tutorAction} deleteEventAction={deleteEventAction} changeEvent={changeEvent}/>
        <div className='kursy_content'>
            {createNewKurs || createNewKurs ? <CreateKursDashboard lektors={lektors} setCreateNewKurs={setCreateNewKurs}/> : <KursesDashboardConnect setCreateNewKurs={setCreateNewKurs}/>}
        </div>
    </div>
  )
}

export const KursyConnect = connect((state) => ({
    emptyState: state.action,
    courses: state.promise.kursy?.payload,
    tutors: state.promise.tutors?.payload,
    addKursAction: state.promise.regGroups?.payload,
    addStudAction: state.promise.addStudentToKurs?.payload,
    updatedInfo: state.promise.groupInfo?.payload,
    deleteStudentAction: state.promise.removeStudentFromKurs?.payload,
    eventAddedAction: state.promise.addEventGroup?.payload,
    tutorAction: state.promise.addTutorToKurs?.payload,
    deleteEventAction: state.promise.deleteEvent?.payload,
    deleteKurs: state.promise.deleteGroup?.payload,
    changeEvent: state.promise.changeEvent?.payload
  }))(Kursy);




// ! добавление курса 
// ! изменения данных курса 
// ! удаление студента с курса 
// ! удаление ивента с курса 
// ! удаление курса 