import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "../css/header.css"
import logo from '../images/imgLanding/Frame 4-2.svg';
import out from "../images/save.svg"
import styles from "../css/header.css"

import { useLocation } from "react-router-dom";
import { store } from '../store/store';
import { actionAddGroupInfo, actionDeleteUserInfo , actionCheckRole} from '../store/actionsReducer';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';



const Header = ({isUpsideDown}) => {
      const navigation = useNavigate()
      const location = useLocation();
      const reduxState = useSelector((state) => state.actions?.role);

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");

    const handleClean = () => {
          store.dispatch(actionDeleteUserInfo())
      //     store.dispatch(actionAddGroupInfo({}))
    }

    useEffect(() => {
      // Проверяем, является ли reduxState.rp пустым объектом
      const isRpEmpty = Object.keys(reduxState?.rp || {}).length === 0;

      if (isRpEmpty) {
          navigation("/login");
      }
  }, [reduxState]);
  
  const handleLogOut = () => {
      navigation("/")
      window.location.reload()
  }

  return (
    <div className='header_wrapper'>
        <div className='logo'>
            <img src={logo} alt=''/>
        </div>
        {
              reduxState?.rp === "user" || reduxState?.rp === "tutor" ?
              !isUpsideDown && (
              <div className='nav_menu'>
                  <ul>
                        <li className={splitLocation[1] === "calendar" ? "active" : "disactive"}>
                              <Link to="/calendar">Kalendarz</Link>
                        </li>
                        <li className={splitLocation[2] === "profil" ? "active" : "disactive"}>
                              <Link to={`/:${1}/profil`}>Profil</Link>
                        </li>
                  </ul>
            </div> 
              ) 
            :
            !isUpsideDown && (<div className='nav_menu'>
                  <ul>
                        <li className={splitLocation[1] === "students" ? "active" : "disactive"} onClick={handleClean}>
                              <Link to="/students">Studenci</Link>
                        </li>
                        <li className={splitLocation[1] === "tutors" ? "active" : "disactive"} onClick={handleClean}>
                              <Link to="/tutors" onClick={handleClean}>Wykładowcy</Link>
                        </li>
                        <li className={splitLocation[1] === "calendar" ? "active" : "disactive"}>
                              <Link to="/calendar">Kalendarz</Link>
                        </li>
                        <li className={splitLocation[1] === "kursy" ? "active" : "disactive"}>
                              <Link to="/kursy">Kursy</Link>
                        </li>
                  </ul>
            </div>)
        }
        <div className='log_out'>
            <button onClick={() => handleLogOut()} style={{cursor: "pointer"}}><img src={out}/></button>
        </div>
    </div>
  )
}

export default Header