import './app.css';
import React, { createContext, useState } from 'react';
import { Provider , connect, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate
} from "react-router-dom";
import { store } from './store/store';

// ! PAGES
import {LoginConnect} from "./pages/Login"
import { UserInfoConnect } from './pages/UserInfo';
import { MyCalendarConnect } from './pages/Calendar';
import { StudentsConnect } from './pages/Students';
import Profil, { PDFGenerator, ProfilConnect } from './pages/Profil';
import { LektorsConnect } from './pages/Lektors';
import { KursyConnect } from './pages/Kursy';
import Landing from './pages/Landing';
import { useEffect } from 'react';

store.subscribe(() => console.log(store.getState()));

function App() {
        const [checkRole , setCheckRole] = useState('')

        
        const ProtectedRoute = ({ element, path }) => {
                const navigate = useNavigate();
              
                useEffect(() => {
                        const isAdmin = () => {
                                if(checkRole === "user"){
                                        return false;
                                }
                                if(checkRole === "tutor"){
                                        return false;
                                }
                                if(checkRole === "admin"){
                                        return true;
                                }
                        };
                
                        if (!isAdmin() && (path === '/students' || path === '/tutors' || path === '/kursy')) {
                        navigate('/login');
                        }
                }, [navigate, path]);
                
                return element;
        };


    return (
            <div className='App'> 
                    <div>
                        <Provider store={store}>
                            <Router>
                                    <Routes>
                                            <Route path="/" element={<Landing />} exact/>
                                            <Route path="/login" element={<LoginConnect checkRole={checkRole}  setCheckRole={setCheckRole}/>} exact/>
                                            <Route path="/:userId" element={<UserInfoConnect />} />
                                            <Route path="/calendar" element={<MyCalendarConnect />} />
                                            <Route path="/students" element={<ProtectedRoute path="/students" element={<StudentsConnect />} />} />
                                            <Route path="/tutors" element={<ProtectedRoute path="/tutors" element={<LektorsConnect />} />} />
                                            <Route path="/kursy" element={<ProtectedRoute path="/kursy" element={<KursyConnect />} />} />
                                            {/* <Route path="/kursy" element={<KursyConnect />} /> */}
                                            <Route path="/:userId/profil" element={<ProfilConnect />} />
                                            <Route path="/legit" element={<PDFGenerator />} />
                                    </Routes>
                            </Router>
                        </Provider>
                    </div>
            </div>
    )
}

export default App;