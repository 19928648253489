import React from 'react'
import "../css/dashboard.css"
import deleteIcon from "../images/trash.svg"
import EditIcon from '@mui/icons-material/Edit';

import legit from '../images/legitimacja.svg'
import { useState } from 'react'
import { useEffect } from 'react'

import { store } from '../store/store'
import { actionRegister, actionRegisterTutors  , actionDeleteStudent  , actionDeleteTutor} from '../actions/actions'
import { connect, useSelector } from 'react-redux'

import { Link, useLocation } from "react-router-dom";
import { Placeholder } from '../pages/Kursy';


import DatePicker from 'react-datepicker';


// ! реализуем отображение с меню данных 

// ... (previous imports)

const StudentDashboard = ({ data, setCheckNewStud , courses , addingButtonClick,addingButtonClick1}) => {
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const location = useLocation();

  const [selectedGroup , setSelectedGroup] = useState('')

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());

  const getGroupNameByGroupId = (courses, targetGroupId) => {
    for (let i = 0; i < courses?.length; i++) {
        if (courses[i].group_id === targetGroupId) {
            return courses[i].group_name;
        }
    }
    // If no match is found
    return null;
  }

  function convertDateFormat(inputDate) {
    // Parse the input date string
    const dateObj = new Date(inputDate);
  
    // Extract the year, month, and day components
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
  
    // Form the formatted date string in the YYYY-MM-DD format
    const formattedDate = `${year}/${month}/${day}`;
  
    return formattedDate;
  }

  useEffect(() => {
    if (data) {
      setFormData({
        ...formData,
        user_id: data.user_id,
        role: data.role,
        first_name: data.first_name,
        last_name: data.last_name,
        zoomLink: data.zoomLink,
        email: data.email,
        date_of_birth: data.date_of_birth,
        nationality: data.nationality,
        id_validity_date: data.id_validity_date,
        passport_number: data.passport_number,
        field_of_study: data.field_of_study,
        study_mode: data.study_mode,
        createdAt: data?.createdAt,
        group_id: data.group_id,
      });
      if(splitLocation[1] !== "tutors"){
        setStartDate(new Date(data.date_of_birth))
        setStartDate1(new Date(data.id_validity_date))
      }
    }
  }, [data]);


  const editStudent = () => {
    setEditing(!editing);
    setCheckNewStud(true);
  };

  
  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };


  // ! добавить номер телефона  ???????
  // ! изменение студента 
  // ! студенты => преподы => курси і потом виводі все єто на каледарь || в конце делаешь разделение что может видеть студент а что может делать препод, выбор домена и добавление на хостинг 

  const addNewStudent = () => {
    setEditing(true);
    setFormData({
      user_id: "",
      role: "user",
      first_name: "",
      last_name: "",
      email: "",
      date_of_birth: "",
      nationality: "",
      id_validity_date: "",
      passport_number: "",
      field_of_study: "",
      study_mode: "",
      group_id: "",
    });
  };

  useEffect(() => {
    if(addingButtonClick || addingButtonClick1){
      addNewStudent()
    }
  }, [addingButtonClick,addingButtonClick1])
  

  const saveStudent = () => {
    // Assuming store and actionRegister are correctly defined
    setFormData({})
    if(splitLocation[1] === "tutors"){
      store.dispatch(actionRegisterTutors({ 
        user_id: formData.user_id,
        role: "tutor",
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        zoomLink: formData.zoomLink,
        field_of_study: formData.field_of_study,
        study_mode: formData.study_mode,
        group_id: formData.group_id }))
        setEditing(false); 
        // window.location.reload()
    }
    if(splitLocation[1] === "students") {
      store.dispatch(actionRegister({ 
        user_id: formData.user_id,
        role: formData.role,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        date_of_birth: convertDateFormat(startDate),
        nationality: formData.nationality,
        id_validity_date: convertDateFormat(startDate1),
        passport_number: formData.passport_number,
        field_of_study: formData.field_of_study,
        study_mode: formData.study_mode ,
        group_id: selectedGroup}))
        setEditing(false); 
        // window.location.reload()
    }
  };


  const deleteStudent = (user_id) => {
    if(splitLocation[1] !== "tutors"){
      store.dispatch(actionDeleteStudent({user_id: user_id}))
    }else{
      store.dispatch(actionDeleteTutor({tutor_id: user_id}))
    }
  }

  const handleSelectGroup = (value) => {
    setSelectedGroup(value)
  }

  const shortenZoomLink = (link) => {
    // Check if link is a string and not undefined
    if (typeof link === 'string' && link.length > 10) {
      return link.substring(0, link.length) + '...'; // Displaying only the first 10 characters as an example
    } else {
      return link; // Return the original link if it's not a valid string
    }
  };
  
function formatDate(dateString) {
    try {
        if (!dateString || typeof dateString !== 'string') {
            throw new Error("Invalid date format");
        }

        const dateParts = dateString.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/);

        if (!dateParts) {
            throw new Error("Invalid date format");
        }

        const [, year, month, day, hours, minutes, seconds, milliseconds] = dateParts;

        const dateObject = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds, milliseconds));

        if (isNaN(dateObject.getTime())) {
            throw new Error("Invalid date");
        }

        const formattedYear = dateObject.getUTCFullYear();
        const formattedMonth = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
        const formattedDay = dateObject.getUTCDate().toString().padStart(2, '0');

        return `${formattedYear}-${formattedMonth}-${formattedDay}`;
    } catch (error) {
        console.error("Error formatting date:", error.message);
        return null; // или другое значение по умолчанию
    }
}


  return (
    <div className='student_information'>
      <div className='student_dashboard'>
        <span onClick={editStudent}>
          <EditIcon sx={{color: "silver"}}/>
          Zmień  {splitLocation[1] !== "tutors" ? "studenta" : "lektora"}
        </span>
        <div className=''>
          <div className='student-info' style={{ border: 'none', boxShadow: 'none' }}>
            <div className='student-info-left'>
              <div className='info-item' key={formData.user_id}>
                      <div className='info-column'>
                        <div className='info_line'>
                          <h4>Name</h4>
                          {editing ? (
                            <input
                              type='text'
                              name='first_name'
                              value={formData.first_name}
                              onChange={(e) => handleInputChange(e, 'first_name')}
                            />
                          ) : (
                            <span>{formData.first_name}</span>
                          )}
                        </div>
                        <div className='info_line'>
                          <h4>Surname</h4>
                          {
                            editing ? (
                              <input
                              type='text'
                              name='last_name'
                              value={formData.last_name}
                              onChange={(e) => handleInputChange(e, 'last_name')}
                            />
                            ) : (
                              <span>{formData.last_name}</span>
                            )
                          }
                        </div>
                        <div className='info_line'>
                          <h4>Email</h4>
                          {
                            editing ? (
                              <input
                              type='text'
                              name='email'
                              value={formData.email}
                              onChange={(e) => handleInputChange(e, 'email')}
                            />
                            ) : (
                              <span>{formData.email}</span>
                            )
                          }
                        </div>  
                        <div className={splitLocation[1] === "tutors" ? "NoShowTutors" : "info_line"}>
                          <h4>Date of bithday</h4>
                          {
                            editing ? (
                            <DatePicker
                            selected={startDate}
                            className="data_picker"
                            onChange={(date) => setStartDate(date)}
                            dateFormat="yyyy/MM/dd"
                            />
                            //   <input
                            //   type='text'
                            //   name='date_of_birth'
                            //   className={splitLocation[1] !== "tutors" ? "showTutors" : "NoShowTutors"}
                            //   value={formData.date_of_birth}
                            //   onChange={(e) => handleInputChange(e, 'date_of_birth')}
                            // />
                            ) : (
                              <span>{formData.date_of_birth}</span>
                            )
                          }
                        </div> 
                        <div className='info_line'>
                          <h4 className={splitLocation[1] !== "tutors" ? "showTutors" : "NoShowTutors"}>Nationality</h4>
                          {
                            editing ? (
                              <input
                              type='text'
                              name='nationality'
                              value={formData.nationality}
                              onChange={(e) => handleInputChange(e, 'nationality')}
                              className={splitLocation[1] !== "tutors" ? "showTutors" : "NoShowTutors"}
                            />
                            ) : (
                              <span>{formData.nationality}</span>
                            )
                          }
                        </div> 
                        </div>
                        <div className='info-column'>
                            <div className={splitLocation[1] === "tutors" ? "NoShowTutors" : "info_line"}>
                              <h4>Valid ID details</h4>
                                {
                                  editing ? (
                                    <DatePicker
                                    selected={startDate1}
                                    className="data_picker"
                                    onChange={(date) => setStartDate1(date)}
                                    dateFormat="yyyy/MM/dd"
                                    />
                                  //   <input
                                  //   type='text'
                                  //   name='id_validity_date'
                                  //   value={formData.id_validity_date}
                                  //   onChange={(e) => handleInputChange(e, 'id_validity_date')}
                                  //   className={splitLocation[1] !== "tutors" ? "showTutors" : "NoShowTutors"}
                                  // />
                                  ) : (
                                    <span>{formData.id_validity_date}</span>
                                  )
                                }
                            </div>
                            <div className='info_line'>
                              <h4 className={splitLocation[1] !== "tutors" ? "showTutors" : "NoShowTutors"}>Passport number</h4>
                              {
                                editing ? (
                                  <input
                                  type='text'
                                  name='passport_number'
                                  value={formData.passport_number}
                                  onChange={(e) => handleInputChange(e, 'passport_number')}
                                  className={splitLocation[1] !== "tutors" ? "showTutors" : "NoShowTutors"}
                                />
                                ) : (
                                  <span>{formData.passport_number}</span>
                                )
                              }
                            </div>
                              <div className='info_line'>
                                <h4>Field of study</h4>
                                {
                                  editing ? (
                                    <input
                                    type='text'
                                    name='field_of_study'
                                    value={formData.field_of_study}
                                    onChange={(e) => handleInputChange(e, 'field_of_study')}
                                  />
                                  ) : (
                                    <span>{formData.field_of_study}</span>
                                  )
                                }
                              </div>
                              <div className='info_line'>
                                <h4 className={splitLocation[1] !== "tutors" ? "showTutors" : "NoShowTutors"}>Study mode</h4>
                                {
                                  editing ? (
                                    <input
                                    type='text'
                                    name='study_mode'
                                    value={formData.study_mode}
                                    onChange={(e) => handleInputChange(e, 'study_mode')}
                                    className={splitLocation[1] !== "tutors" ? "showTutors" : "NoShowTutors"}
                                  />
                                  ) : (
                                    <span>{formData.study_mode}</span>
                                  )
                                }
                              </div>
                              <div className='info_line'>
                                <h4 className={splitLocation[1] !== "students" ? "showTutors" : "NoShowTutors"}>Zoom link</h4>
                                {
                                  editing ? (
                                    <input
                                    type='text'
                                    name='zoomLink'
                                    value={formData.zoomLink}
                                    onChange={(e) => handleInputChange(e, 'zoomLink')}
                                    className={splitLocation[1] !== "students" ? "showTutors" : "NoShowTutors"}
                                  />
                                  ) : (
                                    <span className="shortened-zoom-link" title={formData.zoomLink}>{shortenZoomLink(formData.zoomLink)}</span>
                                  )
                                }
                              </div>
                              <div className={splitLocation[1] === "tutors" ? "NoShowTutors" : "info_line"}>
                                <h4>Group</h4>
                              {
                                editing ? (
                                  <select name="courses" value={selectedGroup} onChange={(e) => handleSelectGroup(e.target.value)} className="groups">
                                    <option value="">select group...</option>
                                   {courses && Array.isArray(courses) && courses.map((course) => (
                                      <option key={course.group_id} value={course.group_id}>
                                        {course.group_name}
                                      </option>
                                    ))}
                                </select>
                                ) : (
                                  <span>{getGroupNameByGroupId(courses, formData?.group_id)}</span>
                                )
                              }
                              </div>
                    </div>
                 </div>
            </div>
            <div className={ splitLocation[1] !== "tutors" ? 'student-info-right' : "NoShowTutors"}>
              <Link style={{textDecoration: "none"}}
              to="/legit" state={{
                first_name: formData?.first_name,
                last_name: formData?.last_name,
                date_of_birth: formData?.date_of_birth,
                nationality: formData?.nationality,
                id_validity_date: formData?.id_validity_date,
                passport_number: formData?.passport_number,
                field_of_study: formData?.field_of_study,
                study_mode: formData?.study_mode,
                createdAt: formatDate(formData?.createdAt)
              }}
              >
                <button className='student-info-right-button'>
                  <img src={legit} style={{ width: '25px', height: '25px' }} alt="Download Legitimation" />
                  Pobierz legytymację
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className='students-buttons'>
          {editing ? (
            <button onClick={saveStudent}>Zapisz</button>
          ) : (
            splitLocation[1] !== "tutors" ? <button onClick={addNewStudent}>Dodaj studenta</button> : <button onClick={addNewStudent}>Dodaj lektora</button> 
          )}
          <button onClick={() => deleteStudent(formData?.user_id)}>
            Usuń {splitLocation[1] !== "tutors" ? "studenta" : "lektora"}
            <img src={deleteIcon} alt="Delete" />
          </button>
        </div>
      </div>
    </div>
  );
};

export const StudentsDashboardConnect = connect((state) => ({
  data: state.actions.user_info,
}))(StudentDashboard);






