import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { actionAllTutors } from '../actions/actions'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { StudentsDashboardConnect } from '../components/StudentDashboard'
import { store } from '../store/store'
import LockPersonIcon from '@mui/icons-material/LockPerson';

import plcHolder from "../images/undraw_teacher_re_sico 1.png"
import { useLocation } from 'react-router-dom'


export const Placeholder = ({ addingButtonClick ,setAddingButtonClick}) => {
  const location = useLocation();
  const { pathname } = location;

  const splitLocation = pathname.split("/");


    // example@email.com

  return (
    <div className='lock'>
        <div className="placehold">
            <img src={plcHolder} alt="placeholder" />
            {
              splitLocation[1] === "tutors" ? (
                <div>
                  <span>Wybierz lektorza albo</span><br/>
                  <strong onClick={() => setAddingButtonClick(true)}>Dodaj nowego</strong>
                </div>
              ) : (
                splitLocation[1] === "students" ? (
                  <div>
                    <span>Wybierz studenta albo</span><br/>
                    <strong  onClick={() => setAddingButtonClick(true)}>Dodaj nowego</strong>
                  </div>
                ) : (
                  <div>
                    <span>Wybierz groupa albo</span><br/>
                    <strong>Dodaj nowego</strong>
                  </div>
                )
              )
            }
        </div>
    </div>
  )
}


const Lektors = ({tutors , deleteAction , registerAction}) => {
    const [lektors , setLektors] = useState([])

    const [checkNewLector , setCheckLektor] = useState(false)
    const reduxState = useSelector((state) => state.actions);
    const [localState, setLocalState] = useState([]);

    const [addingButtonClick , setAddingButtonClick] = useState(false)

    // ! lektors data
    useEffect(() => {
        const fetchData = async () => {
            try {
              await store.dispatch(actionAllTutors());
            } catch (error) {
              console.log(error);
            }
          };
    
          fetchData();
    }, [deleteAction , registerAction])

    useEffect(() => {
        if(tutors){
            setLektors(tutors?.payload)
        }
    }, [tutors])

    useEffect(() => {
        if(reduxState.user_info){
          setLocalState(reduxState || []);
          console.log(reduxState)
        }
    }, [reduxState]);

  return (
    <div className='students_wrapper'>
        <Header />
        <Menu students={lektors} checkNewStudent={checkNewLector} setCheckNewStud={setCheckLektor} setAddingButtonClick={setAddingButtonClick}/>
        {
          localState.user_info || addingButtonClick ?  <StudentsDashboardConnect students={lektors} setCheckNewStud={setCheckLektor} addingButtonClick={addingButtonClick}/> : <Placeholder addingButtonClick={addingButtonClick} setAddingButtonClick={setAddingButtonClick}/>
        }
    </div>
  )
}

export const LektorsConnect = connect((state) => ({
    tutors: state.promise.tutors,
    deleteAction: state.promise.deleteTutor?.payload,
    registerAction: state.promise.regTutors?.payload
  }))(Lektors);