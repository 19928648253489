import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { actionAllKurses, actionAllUsers } from '../actions/actions'
import Header from '../components/Header'
import Menu from '../components/Menu'
import { store } from '../store/store'
import '../css/students.css'
import {StudentsDashboardConnect} from '../components/StudentDashboard'
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { Placeholder } from './Lektors'


const Students = ({ users , courses , deleteAction , registerAction}) => {
    const [students , setStudents] = useState([])
    const [kurs , setKurs] = useState([])

    const [checkNewStudent , setCheckNewStud] = useState(false)

    const [addingButtonClick , setAddingButtonClick] = useState(false)

    // ! students data
    useEffect(() => {
      const fetchData = async () => {
        try {
          await store.dispatch(actionAllUsers());
          await store.dispatch(actionAllKurses());
        } catch (error) {
          console.log(error);
        }
      };

      fetchData();
    }, [checkNewStudent, deleteAction , registerAction]); 


    useEffect(() => {
          if(users){
              setStudents(users?.payload)
          }
          if(courses){
            setKurs(courses)
          }
    }, [users , courses])


    const reduxState = useSelector((state) => state.actions);
    const [localState, setLocalState] = useState([]);


    useEffect(() => {
        if(reduxState.user_info){
          setLocalState(reduxState || []);
        }
    }, [reduxState]);


  return (
    <div className='students_wrapper'>
      <Header />
      <Menu students={students} courses={kurs} checkNewStudent={checkNewStudent} setCheckNewStud={setCheckNewStud} setAddingButtonClick={setAddingButtonClick}/>
      {
        localState.user_info || addingButtonClick ? <StudentsDashboardConnect students={students} courses={kurs} setCheckNewStud={setCheckNewStud} addingButtonClick={addingButtonClick}/> : <Placeholder setAddingButtonClick={setAddingButtonClick}/>
      }
    </div>
  );
};

export const StudentsConnect = connect((state) => ({
  users: state.promise.users,
  courses: state.promise.kursy?.payload,
  deleteAction: state.promise.deleteStudent?.payload,
  registerAction: state.promise.register?.payload
}))(Students);





// export const data = [
//   {
//     name: "Artem",
//     surname: "Gord" , 
//     email: "asdsad@gmail.com",
//     dateOfBithday: "10.12.2005",
//     nationality: "Ukrainian",
//     ExpirationDateID: "10.02.2023",
//     PassportID: "FB213241",
//     FieldofStudy: "english",
//     StudyMode: "Stationary"
// }
// ];